import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import RegistrationClient from "../views/registrationClient";
import connexionClient from "../components/login.components";
import supportClient from "../views/supportClient";
import ProfilClient from "../views/profilClient";
import UpdateProfilClient from "../views/updateProfilClient";
import DetailParcoursClient from "../views/detailParcoursClient";
import AllParcoursClient from "../views/parcoursClient";
import CategoryTrips from "../views/TripsByCategoryClient";
import ResetPasswordClient from "../views/resetPasswordClient";
import KeyIndicators from "../views/keyIndicators";
import ProtectedRoute from "../components/protectedRoute.components";
import Legal from "../views/Legal";
import Products from "../components/Stripe/Products";
import Checkout from "../components/Stripe/Checkout";

export default function Views() {
  return (
    <Router>
      <Switch>
        <Route path="/connexion" component={connexionClient}></Route>
        <Route path="/inscription" component={RegistrationClient}></Route>
        <Route path="/mentionslegales" component={Legal}></Route>
        <Route path="/products" component={Products} />
        <Route path="/checkout" component={Checkout} />
        <ProtectedRoute
          path="/support"
          component={supportClient}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/profil"
          component={ProfilClient}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/majprofil"
          component={UpdateProfilClient}
        ></ProtectedRoute>

        <ProtectedRoute path="/parcours/:id" component={DetailParcoursClient} />

        <ProtectedRoute
          path="/"
          exact
          component={AllParcoursClient}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/categorie/:id"
          component={CategoryTrips}
        ></ProtectedRoute>
        <Route
          path="/reset_password/clients/:token"
          component={ResetPasswordClient}
        ></Route>
        <ProtectedRoute
          path="/chiffresclés"
          component={KeyIndicators}
        ></ProtectedRoute>
      </Switch>
    </Router>
  );
}
