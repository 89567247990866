import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const PUBLIC_KEY =
  "pk_live_51LI6oMJi4sLTTdCv3ZVW2VEVvvdBCoAks9mxIAbm34aQRhj6VE3HKfGmy9CzGxzG6jbyDXnAEx3QIBGqBgRwVX6v00iVBwJJWc";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Checkout = ({ selectedProduct, quantity, client }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="intro-container flex flex-col gap-4">
        <h1 className="checkout-title">Vos informations</h1>
        <div className="flex flex-row gap-2">
          {" "}
          <h2>Nom d'utilisateur :</h2>
          <p>{client.username}</p>
        </div>
        <div className="flex flex-row gap-64">
          <div className="flex flex-row gap-2">
            <h2>Société :</h2>
            <p>{client.storeName}</p>
          </div>
          <div className="flex flex-row gap-2">
            <h2>SIRET: </h2> <p>{client.siret}</p>
          </div>
        </div>
      </div>
      <div className="intro-container">
        <Elements stripe={stripeTestPromise}>
          <CheckoutForm
            selectedProduct={selectedProduct}
            quantity={quantity}
            client={client}
          />
        </Elements>
      </div>
    </div>
  );
};

export default Checkout;
