import React from "react";
import SideBar from "../components/sideBar.components";
import Faq from "../components/FAQ/faq";

export default function supportClient() {
  return (
    <div className="flex flex-row bg-bikeexplogray-dark w-full h-screen">
      <SideBar />
      <div className="flex flex-col gap-3 w-full h-full mx-4 my-4">
        <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3">
          {" "}
          Aide{" "}
        </h1>
        <div className="flex flex-col gap-6">
          <h2 className="bg-salmon text-white rounded-lg p-1 px-2">
            {" "}
            Questions fréquentes
          </h2>
          <section className="flex flex-row gap-6 mx-4">
            <Faq />
          </section>

          <p className="text-white mx-4 text-center bg-bikeexplogray-light rounded-lg p-4">
            Si toutefois vous aviez d'autres questions ou si vous souhaitez nous
            faire un retour sur l'application ou votre interface pro, n'hésitez
            pas à nous contacter à parcours@bikeexplo.com
          </p>
        </div>
      </div>
    </div>
  );
}
