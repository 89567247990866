import axios from "axios";
import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Checkout from "./Checkout";
import SideBarClient from "../sideBar.components";

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [client, setClient] = useState({});
  const [modal2Visible, setModal2Visible] = useState(false);
  const [product, setProduct] = useState([]);

  // VARS
  var tok = localStorage.getItem("token");
  var decoded = jwt_decode(tok);
  var Id = decoded.id;

  useEffect(() => {
    const fetchClient = async () => {
      const client = await axios(`https://api.bikeexplo.com/api/clients/${Id}`);
      setClient(client.data);
    };
    fetchClient();

    const fetchProduct = async () => {
      const product = await axios(`https://api.bikeexplo.com/api/product`);
      setProduct(product.data);
    };
    fetchProduct();
    console.log(product);
  }, [Id]);

  const handlePurchase = (product) => () => {
    alert("Veuillez nous contacter pour effectuer un achat.")
    // alert("Veuillez nous contacter en indiquant le pack et la quantité souhaité afin que nous vous fournissions une facture. Après réception du paiement, vous serez crédité.")
    // setSelectedProduct(product);
    // setModalVisible(true);
  };

  async function handleFreebie(e) {
    e.preventDefault();

    var axios = require("axios");
    var data = { free_codes: false, credit: 30 };
    var config = {
      method: "put",
      url: "https://api.bikeexplo.com/api/clients/" + Id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    if (client.free_codes === true) {
      axios(config)
        .then(function (response) {
          const mess = "Vous bénéficiez de 30 codes gratuits";
          window.location.reload();
          console.log(mess);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setModal2Visible(true);
    }
  }

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarClient />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <div className="flex flex-row justify-between items-center rounded-lg bg-bikeexplogray-light p-3 w-full">
          <h1 className="text-white font-bold w-4/5 flex flex-row gap-4 text-xs md:text-base mx-1">
            <svg
              width="20"
              height="20"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 15V17.3333M6 10.3333V5.66667C6 4.42899 6.52678 3.242 7.46447 2.36683C8.40215 1.49167 9.67392 1 11 1C12.3261 1 13.5979 1.49167 14.5355 2.36683C15.4732 3.242 16 4.42899 16 5.66667L6 10.3333ZM3.5 22H18.5C19.163 22 19.7989 21.7542 20.2678 21.3166C20.7366 20.879 21 20.2855 21 19.6667V12.6667C21 12.0478 20.7366 11.4543 20.2678 11.0168C19.7989 10.5792 19.163 10.3333 18.5 10.3333H3.5C2.83696 10.3333 2.20107 10.5792 1.73223 11.0168C1.26339 11.4543 1 12.0478 1 12.6667V19.6667C1 20.2855 1.26339 20.879 1.73223 21.3166C2.20107 21.7542 2.83696 22 3.5 22Z"
                stroke="#FA8072"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Achat de codes
          </h1>
        </div>
        {!modalVisible && (
          <div className="flex flex-col gap-8">
            <div className="intro-container flex flex-row gap-5">
              <div>
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.7418 38.25H32.256H21.7418ZM27 6.75V9V6.75ZM41.319 12.681L39.7282 14.2717L41.319 12.681ZM47.25 27H45H47.25ZM9 27H6.75H9ZM14.2717 14.2717L12.681 12.681L14.2717 14.2717ZM19.044 34.956C17.471 33.3825 16.3998 31.378 15.9659 29.1958C15.5321 27.0136 15.7551 24.7517 16.6066 22.6962C17.4582 20.6407 18.9002 18.8839 20.7501 17.6479C22.6001 16.4118 24.7751 15.7521 27 15.7521C29.2249 15.7521 31.3999 16.4118 33.2499 17.6479C35.0998 18.8839 36.5418 20.6407 37.3934 22.6962C38.2449 24.7517 38.4679 27.0136 38.034 29.1958C37.6002 31.378 36.529 33.3825 34.956 34.956L33.723 36.1868C33.0181 36.8918 32.459 37.7288 32.0775 38.6499C31.6961 39.571 31.4999 40.5583 31.5 41.5552V42.75C31.5 43.9435 31.0259 45.0881 30.182 45.932C29.3381 46.7759 28.1935 47.25 27 47.25C25.8065 47.25 24.6619 46.7759 23.818 45.932C22.9741 45.0881 22.5 43.9435 22.5 42.75V41.5552C22.5 39.5415 21.699 37.6088 20.277 36.1868L19.044 34.956Z"
                    stroke="#FA8072"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <p>
                Sur Bike Explo, vos clients pourront déverrouiller les parcours
                “premium” grâce à un code que vous leur fournissez. L’achat d’un
                ou plusieurs pack(s) de codes vous permettra de débloquer le
                générateur de codes en page d’accueil et ainsi donner accès à
                vos plus beaux parcours !
              </p>
            </div>
            <div className="text-white flex flex-row gap-2 text-center justify-center">
              Il vous reste
              <div className="text-salmon font-semibold">{client?.credit}</div>
              code(s) à déclencher.
            </div>
            <div className="flex flex-row gap-4 justify-evenly">
              <section className="product-container">
                <h2 className="font-semibold">Pack de 30 codes</h2>
                <h3 className="price">GRATUIT</h3>
                <p>Offre test !* </p>
                <p>
                  Nous vous offrons un pack de 30 codes pour tester Bike Explo !
                  <br />
                  *offre valable une seule fois
                </p>

                <button
                  type="button"
                  className="buy-button"
                  onClick={handleFreebie}
                >
                  J'EN PROFITE !
                </button>
                {modal2Visible && (
                  <div className="text-salmon">
                    Vous avez déjà bénéficié de notre offre test, vous pouvez
                    commencer à monétiser vos parcours !
                  </div>
                )}
              </section>
              {product.map((product) => (
                <div className="product" key={product.id}>
                  <section className="product-container">
                    <h2 className="font-semibold">{product.name}</h2>
                    {quantity ? (
                      <h3 className="price">{product.price * quantity}€ TTC</h3>
                    ) : (
                      <h3 className="price">{product.price + "€"}</h3>
                    )}
                    <p>{product.desc}</p>

                    <div className="flex flex-row justify-center gap-1">
                      <h4 className="mt-2">Quantité :</h4>
                      <input
                        className="quantity"
                        type="number"
                        required
                        min="1"
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                    <p>
                      Veuillez nous contacter en indiquant le pack et la quantité souhaité afin que nous vous fournissions une facture. Après réception du paiement, vous serez crédité.
                    </p>
                    <button
                      className="buy-button"
                      type="button"
                      onClick={handlePurchase(product)}
                    >
                      ACHETER
                    </button>
                  </section>
                </div>
              ))}{" "}
            </div>
          </div>
        )}
        {modalVisible && (
          <Checkout
            selectedProduct={selectedProduct}
            quantity={quantity}
            client={client}
          />
        )}
      </div>
    </div>
  );
};

export default Products;
