import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import jwt_decode from "jwt-decode";

import PaymentError from "./PaymentError";
import PaymentSuccess from "./PaymentSuccess";

const CheckoutForm = ({ selectedProduct, quantity, client }) => {
  const stripe = useStripe();
  const elements = useElements();

  if (localStorage.getItem("token")) {
    var tok = localStorage.getItem("token");
    var decoded = jwt_decode(tok);
    var Id = decoded.id;
  }

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  let totalPrice = selectedProduct.price;

  let price = totalPrice * quantity;

  var TVA = price * 0.2;
  TVA = TVA.toFixed(2);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const billingDetails = {
      name: client.storeName,
      email: client.email,
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    if (!error) {
      console.log("Stripe 23 | token generated!", paymentMethod);
      try {
        const { id } = paymentMethod;
        const response = await axios.post(
          "https://api.bikeexplo.com/stripe/charge",
          {
            amount: selectedProduct.price.toString().replace(".", ""),
            id: id,
            billing_details: billingDetails,
            receipt_email: client.email,
          }
        );
        if (response.data.success) {
          console.log("CheckoutForm.js 25 | payment successful!");
          setSuccess(true);
          let numberCodes = 10 * quantity;
          axios.put(`https://api.bikeexplo.com/api/clients/${Id}`, {
            credit: numberCodes,
          });
        }
      } catch (error) {
        console.log("CheckoutForm.js 28 | ", error);
        setError(true);
      }
    } else {
      console.log(error.message);
    }
  };
  const CardElementOptions = {
    style: {
      base: {
        color: "#fff",
        fontSize: "16px",
      },
      invalid: {
        color: "#FF0000",
        iconColor: "#FF0000",
      },
      complete: {
        color: "#008000",
      },
    },
    hidePostalCode: true,
  };
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <h1 className="checkout-title">Vos achats</h1>
      <div className="flex flex-col gap-1">
        <p className="text-lg flex flex-row gap-2">
          Total (TTC) :
          <p className="text-salmon drop-shadow-xl font-semibold">{price}€</p>
        </p>
        <p className="text-sm flex flex-row gap-2">
          dont TVA :<p className="">{TVA}€</p>
        </p>
      </div>
      <section className="flex flex-col gap-1">
        <h2 className="font-semibold">Informations de paiement</h2>
        <div className="bg-bikeexplogray-dark rounded-lg p-4">
          <CardElement options={CardElementOptions} />
        </div>
      </section>

      <button className="buy-button">CONFIRMER LE PAIEMENT</button>
      {success && <PaymentSuccess />}
      {error && <PaymentError />}
    </form>
  );
};

export default CheckoutForm;
