import React from "react";
import SideBar from "../components/sideBar.components";

export default function legal() {
  return (
    <div className="flex flex-row bg-bikeexplogray-dark w-full h-full">
      <SideBar />
      <div className="flex flex-col gap-3 w-full mx-4 my-4">
        <h1 className="flex flex-row gap-2 rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3">
          <svg
            className="w-5 h-5 lg:w-6 lg:h-6 text-salmon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            ></path>
          </svg>{" "}
          Mentions légales{" "}
        </h1>
        <div className="flex flex-row gap-4 w-full text-white">
          <div className="w-1/2 flex flex-col mx-4 text-justify">
            <h1 className="text-center my-4 font-bold">
              Conditions Générales de Vente de services
            </h1>
            <h2 className="font-semibold my-2">
              ARTICLE 1 - CHAMP D'APPLICATION
            </h2>
            <p>
              Les présentes Conditions Générales de Vente, ci-après « CGV »,
              s'appliquent, sans restriction ni réserve à tout achat de services
              proposés par la Société Soft Mobility Company, ci-après désignée
              le « Prestataire », aux clients professionnels, ci-après désignés
              le «Client», sur son site Internet www.bikeexplo.com et
              l’application mobile Bike Explo, désignés ci-après le Site. Les
              coordonnées du Prestataire sont les suivantes : Soft Mobility
              Company SAS au capital de 10 000 euros Siège social : 1, bd Louis
              Salvator 13006 Marseille RCS Marseille 881 312 557
            </p>
            <p>
              Le Client déclare avoir pris connaissance des présentes Conditions
              Générales de Ventes et les avoir acceptées avant la mise en œuvre
              de la procédure de commande ainsi que des conditions générales
              d'utilisation du Site. Sauf preuve contraire, les données
              enregistrées par le Prestataire constituent la preuve de
              l'ensemble des transactions passées avec le Client via le Site. La
              validation de la commande de Services par le Client vaut
              acceptation sans restriction ni réserve des présentes Conditions
              Générales de Vente. Le Client reconnaît avoir la capacité requise
              pour contracter et acquérir les Services proposés, ces Conditions
              Générales de Vente pouvant faire l'objet de modifications
              ultérieures. En cas de fourniture de prestations de service à
              l'étranger, il convient de se renseigner, le cas échéant, sur les
              lois applicables dans les pays visés, de veiller au respect des
              contraintes éventuelles qu'elles imposent et de traduire les
              conditions de vente dans les langues appropriées.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 2 - SERVICES ET PRODUITS PROPOSÉS{" "}
            </h2>
            <p>
              Le Prestataire propose sur l’application Bike Explo, des Parcours
              vélo et des services en découlant: Trois types principaux de
              Parcours existent :
            </p>
            <ul>
              <li>
                Les Parcours “sportifs” sont définis comme suit : Parcours
                comprenant seulement une trace GPS ou des points GPS (GPX,
                KML…).
              </li>
              <li>
                Les Parcours “touristiques” sont définis comme suit : Parcours
                comprenant seulement une trace GPS ou des points GPS (GPX,
                KML…), des contenus textuels et photos liés aux points
                d'intérêt.{" "}
              </li>
              <li>
                Les Parcours “événementiel” sont définis comme suit : Parcours
                comprenant seulement une trace GPS ou des points GPS (GPX,
                KML…), des contenus textuels et photos liés aux points
                d'intérêt. Parcours temporaire ou pour un événement à une date
                définie.
              </li>{" "}
            </ul>
            <p>
              Pour chaque type de Parcours, le Client à l’obligation de fournir
              au Prestataire une trace GPS vérifiée et sécurisée ou des points
              GPS permettant de créer une trace vérifiée et sécurisée. Les
              contenus textuels et visuels de chaque type de parcours peuvent
              être fournis par le Client.
            </p>
            <p>
              Le Prestataire propose la création de contenu textuel et visuel
              sur des points d'intérêt et, plus globalement, la rédaction de
              Parcours à partir d’une trace GPS ou de points GPS.
            </p>
            <p>
              Le Prestataire met à disposition des Clients un kit de démarrage
              comprenant : Un sticker Bike Explo à coller sur vitrine. 100
              flyers Bike Explo. Les frais de création de compte client.
            </p>
            <h2 className="font-semibold my-2">ARTICLE 3 - COMMANDE</h2>
            <p>
              Ces parcours sont accessibles au grand public par le biais d’un
              code que le Client lui donne.
            </p>
            <p>Pour la création du code les étapes sont les suivantes :</p>
            <ul>
              <li>Etape 1 : Connexion sur le site pro.bikeexplo.com </li>
              <li>Etape 2 : Sélection du parcours </li>
              <li>Etape 3 : Validation </li>
              <li>Etape 4 : Apparition du code dans une fenêtre pop up</li>
              <li>
                Etape 5 : Reporting des codes utilisés dans “chiffres clés”
              </li>
            </ul>
            <p>
              Il appartient au Client de vérifier l'exactitude de la commande et
              de signaler immédiatement toute erreur. Le Prestataire se réserve
              le droit d'annuler ou de refuser toute commande d'un Client avec
              lequel il existerait un litige relatif au paiement d'une commande
              antérieure.
            </p>
            <h2 className="font-semibold my-2">ARTICLE 4 - TARIFS</h2>
            <p>
              Les codes d’utilisation des Parcours proposés par le Prestataire
              sont fournis aux tarifs en vigueur sur le Site, lors de
              l'enregistrement de la commande par le Prestataire. Les prix sont
              exprimés en Euros TTC. Ces tarifs sont fermes et non révisables
              pendant leur période de validité, telle qu'indiqué sur le Site, le
              Prestataire se réservant le droit, hors cette période de validité,
              de modifier les prix à tout moment. Le kit de démarrage proposé
              par le Prestataire sont fournis aux tarifs en vigueur selon les
              conditions définies en Annexe. La création de contenu textuel et
              visuel proposée par le Prestataire est fournie aux tarifs en
              vigueur selon les conditions définies en Annexe.{" "}
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 5 - CONDITIONS DE PAIEMENT
            </h2>
            <p>
              Le paiement par le Client des Services tel que définis à l'article
              2 (Kit de démarrage, création de contenu), à l'exception des codes
              d’utilisation, sera réalisé par virement bancaire suite à la
              facturation une fois le Service réalisé par le Prestataire. La
              facturation des codes d’utilisation des Parcours est effectuée a
              posteriori selon le rapport d’utilisation visible dans les
              chiffres clés sur le site pro.bikeexplo.com le dernier jour du mois.
              Dans ce cadre, les paiements seront réalisés par virement bancaire
              par le biais d’une facturation mensuelle. Le Client règlera alors
              le montant des sommes dûes dans un délai de 15 jours à compter de
              la date de la facture du Prestataire. Seules les informations
              nécessaires à la facturation sont conservées par le Prestataire.
              Les paiements effectués par le Client ne seront considérés comme
              définitifs qu'après encaissement effectif des sommes dues par le
              Prestataire. En outre, le Prestataire se réserve le droit, en cas
              de non-respect des conditions de paiement figurant ci-dessus, de
              suspendre ou d'annuler la fourniture des Services commandés par le
              Client et/ou de suspendre l'exécution de ses obligations. Aucun
              frais supplémentaire, supérieur aux coûts supportés par le
              Prestataire pour l'utilisation d'un moyen de paiement ne pourra
              être facturé au Client.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 6 - FOURNITURE DES PRESTATIONS
            </h2>
            <p>
              Les Services commandés par le Client seront fournis dans un délai
              de 2 semaines à partir de la réception de l’ensemble des éléments
              nécessaires à la création d’un parcours. Le Prestataire s'engage à
              faire ses meilleurs efforts pour fournir les Services commandés
              par le Client, dans le cadre d'une obligation de moyens et dans
              les délais ci-dessus précisés. Si les services commandés n'ont pas
              été fournis dans un délai de 2 semaines à partir de la réception
              de l’ensemble des éléments nécessaires à la création d’un
              parcours, pour toute autre cause que la force majeure ou le fait
              du Client, la vente pourra être résolue à la demande écrite du
              Client dans les conditions prévues aux articles L. 216-2, L. 216-3
              et L. 241-4 du Code de la consommation. Les sommes versées par le
              Client lui seront alors restituées au plus tard dans les quatorze
              jours qui suivent la date de dénonciation du contrat, à
              l'exclusion de toute indemnisation ou retenue.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 7 - RESPONSABILITE DU PRESTATAIRE - GARANTIE
            </h2>
            <p>
              Le Prestataire garantit, conformément aux dispositions légales et
              sans paiement complémentaire, le Client, contre tout défaut de
              conformité ou vice caché, provenant d'un défaut de conception ou
              de réalisation des Services commandés dans les conditions et selon
              les modalités définies en annexe aux présentes Conditions
              Générales de Vente. Afin de faire valoir ses droits, le Client
              devra informer le Prestataire, par écrit, de l'existence des vices
              ou défauts de conformité dans un délai maximum de 10 jours à
              compter de la fourniture des Services. Le Prestataire remboursera,
              rectifiera ou fera rectifier dans la mesure du possible les
              services jugés défectueux dans les meilleurs délais et au plus
              tard dans les 15 jours suivant la constatation par le Prestataire
              du défaut ou du vice. Le remboursement s'effectuera par crédit sur
              le compte bancaire du Client ou par chèque bancaire adressé au
              Client. La garantie du Prestataire est limitée au remboursement
              des Services effectivement payés par le Client et le Prestataire
              ne pourra être considéré comme responsable ni défaillant pour tout
              retard ou inexécution consécutif à la survenance d'un cas de force
              majeure habituellement reconnu par la jurisprudence française. Les
              Services fournis par l'intermédiaire du Site du Prestataire sont
              conformes à la réglementation en vigueur en France. La
              responsabilité du Prestataire ne saurait être engagée en cas de
              non respect de la législation du pays dans lequel les Services
              sont fournis, qu'il appartient au Client, qui est seul responsable
              du choix des Services demandés, de vérifier.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 8 - INFORMATIQUES ET LIBERTÉS
            </h2>
            <p>
              En application du Règlement Européen 2016/679 retranscrit par la
              Loi du 20 Juin 2018 numéro 2018-493 relatif à la protection des
              données personnelles, il est rappelé que les données nominatives
              qui sont demandées au Client sont nécessaires au traitement de sa
              commande et à l'établissement des factures, notamment. Ces données
              peuvent être communiquées aux éventuels partenaires du Prestataire
              chargés de l'exécution, du traitement, de la gestion et du
              paiement des commandes. Le Client dispose, conformément aux
              réglementations nationales et européennes en vigueur d'un droit
              d'accès permanent, de modification, de rectification et
              d'opposition s'agissant des informations le concernant. Ce droit
              peut être exercé dans les conditions et selon les modalités
              définies sur le Site. Pour plus de précisions, veuillez vous
              référer à la Politique de Confidentialité de Soft Mobility
              Company.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 9 - PROPRIETE INTELLECTUELLE
            </h2>
            <p>
              Le contenu de l’application est la propriété de Soft Mobility
              Company et de ses partenaires et est protégé par les lois
              françaises et internationales relatives à la propriété
              intellectuelle. Toute reproduction totale ou partielle de ce
              contenu est strictement interdite et est susceptible de constituer
              un délit de contrefaçon. En outre, le Prestataire reste
              propriétaire de tous les droits de propriété intellectuelle sur
              les photographies, présentations, études, dessins, modèles,
              prototypes, etc, réalisés, même à la demande du Client, en vue de
              la fourniture des Services au Client. Le Client s'interdit donc
              toute reproduction ou exploitation desdites études, dessins,
              modèles et prototypes, etc, sans l'autorisation expresse, écrite
              et préalable du Prestataire qui peut la conditionner à une
              contrepartie financière.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 10 - DROIT APPLICABLE - LANGUE
            </h2>
            <p>
              Les présentes Conditions Générales de Vente et les opérations qui
              en découlent sont régies et soumises au droit français. Les
              présentes Conditions Générales de Vente sont rédigées en langue
              française. Dans le cas où elles seraient traduites en une ou
              plusieurs langues étrangères, seul le texte français ferait foi en
              cas de litige.
            </p>
            <h2 className="font-semibold my-2">ARTICLE 11 - LITIGES</h2>
            <p>
              Tous les litiges auxquels les opérations d'achat et de vente
              conclues en application des présentes conditions générales de
              vente pourraient donner lieu, concernant tant leur validité, leur
              interprétation, leur exécution, leur résiliation, leurs
              conséquences et leurs suites et qui n'auraient pu être résolues
              entre le vendeur et le client seront soumis aux tribunaux
              compétents dans les conditions de droit commun. Le Client est
              informé qu'il peut en tout état de cause recourir à une médiation
              conventionnelle, notamment auprès de la Commission de la médiation
              de la consommation (C. consom. art. L. 612-1) ou auprès des
              éventuelles instances de médiation sectorielles existantes, ou à
              tout mode alternatif de règlement des différends (conciliation,
              par exemple) en cas de contestation.
            </p>
            <h2 className="font-semibold my-2">
              ARTICLE 12 - INFORMATION PRÉCONTRACTUELLE - ACCEPTATION DU CLIENT
            </h2>
            <p>
              Le Client reconnaît avoir eu communication, préalablement à la
              passation de sa commande et à la conclusion du contrat, d'une
              manière lisible et compréhensible, des présentes Conditions
              Générales de Vente et de toutes les informations listées à
              l'article L. 221-5 du Code de la consommation, et notamment les
              informations suivantes : Les caractéristiques essentielles des
              Services, compte tenu du support de communication utilisé et du
              Service concerné. Le prix des Services et des frais annexes. En
              l'absence d'exécution immédiate du contrat, la date ou le délai
              auquel le Prestataire s'engage à fournir les Services commandés.
              Les informations relatives à l'identité du Prestataire, à ses
              coordonnées postales, téléphoniques et électroniques. Les
              informations relatives aux garanties légales et contractuelles et
              à leurs modalités de mise en œuvre. La possibilité de recourir à
              une médiation conventionnelle en cas de litige. Les informations
              relatives au droit de rétractation (existence, conditions, délai,
              modalités d'exercice de ce droit et formulaire type de
              rétractation), aux modalités de résiliation et autres conditions
              contractuelles importantes. Les moyens de paiement acceptés.
            </p>
            <h1 className="font-bold">Annexe Conditions Générales de Vente </h1>
            <h2 className="font-semibold my-2">Tarification</h2>
            <p>Le Kit de démarrage comprend :</p>
            <ul>
              <li>Un sticker Bike Explo à coller sur vitrine.</li>
              <li>100 flyers Bike Explo. </li>
              <li>Les frais de création de compte client.</li>
            </ul>
            <p>
              Le Kit de démarrage est facturé 100€ HT dès la signature des
              conditions particulières par le Client. Dans le cas où le Loueur
              n’a pas fourni les éléments d’un Parcours, la tarification de la
              Création de contenu par le Prestataire est définie selon le nombre
              de points d'intérêt du Parcours.
            </p>{" "}
            <p>La tarification suit le modèle suivant : </p>
            <table className="border">
              <thead className="border">
                <tr className="border">
                  <th className="border">Nombre de Points d'intérêt</th>{" "}
                  <th className="border">Prix du contenu texte HT</th>{" "}
                  <th className="border">Prix du contenu visuel* HT</th>{" "}
                  <th className="border">Prix total du Parcours HT</th>
                </tr>
              </thead>{" "}
              <tbody className="border text-center">
                <tr className="border">
                  <td className="border">Inférieur à 10</td>{" "}
                  <td className="border">150€</td>{" "}
                  <td className="border">50€</td>{" "}
                  <td className="border">200€ </td>
                </tr>
                <tr className="border">
                  <td className="border">Entre 10 et 15</td>{" "}
                  <td className="border">225€</td>{" "}
                  <td className="border">75€</td>{" "}
                  <td className="border">300€</td>{" "}
                </tr>
                <tr className="border">
                  <td className="border">Entre 15 et 20</td>{" "}
                  <td className="border">300€</td>{" "}
                  <td className="border">125€</td>{" "}
                  <td className="border">425€</td>{" "}
                </tr>
                <tr className="border">
                  <td className="border">Supérieur à 20</td>{" "}
                  <td className="border">425€</td>{" "}
                  <td className="border">175€ </td>
                  <td className="border">600€</td>
                </tr>
              </tbody>
            </table>
            <p>*hors licence d’exploitation</p>
            <p>
              Dans ce cadre, les licences d’exploitation affiliées aux contenus
              visuels achetés par le Prestataire seront facturées au Client.{" "}
            </p>
          </div>
          <div className="w-1/2 flex flex-col mx-4 text-justify">
            <h1 className="text-center my-4 font-bold">Mentions légales</h1>
            <p>
              Ce site est édité par SOFT MOBILITY COMPANY, SAS au capital de 10
              000€ Siège social : 1, bd Louis Salvator, 13006 Marseille
              Responsable de la publication : Sabrina Grassi Hébergeur : OVH,
              140 Quai du Sartel - 59100 Roubaix - France
            </p>
            <h3>Informations générales </h3>
            <p>
              Soft Mobility Company, à travers son application Bike Explo, a
              pour but de proposer des parcours vélo sur mesure. Conditions
              générales d'utilisation
            </p>
            <h2 className="font-semibold my-2">I. OBJET</h2>
            <p>
              Les présentes conditions générales ont pour objet de fixer les
              règles d’utilisation des sites Bikexplo.com, de Bike Explo Admin,
              Bike Explo Pro ainsi que l’application Bike Explo par les
              utilisateurs, ainsi que les engagements de Soft Mobility Company.
              Tout utilisateur s’engage à respecter ces conditions lors de
              chacune de ses visites sur le site bikeexplo.com et sur
              l’application Bike Explo. Les utilisateurs enregistrés ou non
              reconnaissent avoir pris connaissance des présentes conditions
              générales et déclarent les accepter sans réserve.
            </p>
            <h2 className="font-semibold my-2">II. CODE DE CONDUITE</h2>
            <h3>Utilisation des éléments d'identification</h3>
            <p>
              Les éléments d’identification (identifiant Bike Explo et mot de
              passe) permettant à l’utilisateur enregistré de s’identifier et de
              se connecter à la partie privée du site sont personnels et
              confidentiels. Le mot de passe est modifiable en ligne par
              l'utilisateur enregistré, notamment en cas de perte ou de vol du
              mot de passe. L’utilisateur enregistré est entièrement responsable
              de l’utilisation des éléments d’identification le concernant. Il
              s’engage à conserver secret ses éléments d’identification et à ne
              pas les divulguer sous quelque forme que ce soit. Lorsque
              l'utilisateur s'enregistre sur Bikeexplo.com , il assure ne pas
              avoir déjà des éléments d'identification qu'il aurait obtenus au
              préalable. En cas de perte ou de vol du login, l'utilisateur
              enregistré devra se réinscrire.
            </p>

            <h2 className="font-semibold my-2">
              III. RÈGLES D’USAGE D’INTERNET
            </h2>
            <p>
              Tout utilisateur des sites déclare accepter les caractéristiques
              et les limites d’Internet et en particulier reconnaître : Qu’il
              est seul responsable de l’utilisation qu’il fait des informations
              présentes sur le site. En conséquence, Soft Mobility Company ne
              saurait être tenue responsable de quelconques dommages directs ou
              indirects découlant de l’utilisation de ces informations. Qu’il
              est seul responsable de l’utilisation du contenu des sites ayant
              un lien hypertexte vers ou depuis le site Bikeexplo.com. Soft
              Mobility Company décline toute responsabilité quant à leur
              contenu. Que la communication de ses éléments d’identification et
              d’une manière générale de toute information jugée par lui comme
              sensible ou confidentielle est interdite. Qu’il a connaissance de
              la nature d’Internet et en particulier de ses performances
              techniques et des temps de réponse pour consulter, interroger ou
              transférer des informations. Soft Mobility Company ne saurait être
              tenue responsable des dysfonctionnements du réseau internet. Qu’il
              lui appartient de prendre toutes mesures appropriées de façon à
              protéger ses propres données et logiciels de la contamination par
              d’éventuels virus circulant à travers le site Bikeexplo.com. Soft
              Mobility Company ne pourra être tenue responsable des dégâts
              éventuels subis.
            </p>
            <h2 className="font-semibold my-2">IV. RESPONSABILITÉ</h2>
            <p>
              Alors même que la société Soft Mobility Company s’efforce que le
              contenu de Bikeexplo.com soit le plus fiable possible, elle ne
              peut donner aucune garantie quant à l’ensemble des informations
              présentes sur le site et l’application, qu’elles soient fournies
              par Bikeexplo.com ou par ses partenaires. De même, Soft Mobility
              Company n’apporte aucune garantie quant à l’utilisation desdites
              informations. Soft Mobility Company ne saurait en conséquence être
              tenue pour responsable du préjudice ou dommage pouvant résulter de
              l’utilisation des informations présentes sur Bikexplo.com, ni
              d’erreurs ou omissions dans celles-ci. Soft Mobility Company
              décline toute responsabilité en ce qui concerne les contenus des
              sites web édités par des tiers et accessibles depuis Soft Mobility
              Company par des liens hypertextes. Concernant les sites marchands
              mentionnés dans les publicités affichées sur Bikeexplo.com ou dans
              n'importe quelle rubrique du site, Soft Mobility Company n’est
              nullement responsable des litiges qui pourraient survenir entre un
              site marchand et un utilisateur.
            </p>

            <h2 className="font-semibold my-2">V. POLITIQUE DE VIE PRIVÉE</h2>
            <p>
              Lorsque vous utilisez Bike Explo, vous acceptez notre politique de
              confidentialité.
            </p>

            <h2 className="font-semibold my-2">
              VI. PROTECTION DES DROITS DE PROPRIÉTÉ INTELLECTUELLE
            </h2>
            <p>
              Par l’accès à Bikeexplo.com, Soft Mobility Company consent à
              l’utilisateur qui l’accepte, une licence d’utilisation pour les
              informations consultées sur les sites de Bikeexplo.com, de Bike
              Explo Admin, Bike Explo Pro ainsi que l’application Bike Explo. La
              licence confère à l’utilisateur un droit d’usage privé, non
              collectif et non exclusif sur les informations consultées. Elle
              comprend le droit de reproduire et/ou de stocker à des fins
              strictement personnelles. Toute mise en réseau, toute rediffusion,
              sous quelque forme, même partielle, sont interdites. Ce droit est
              personnel, il est réservé à l’usage exclusif et non collectif de
              l’utilisateur. Il n’est transmissible en aucune manière.
            </p>

            <h2 className="font-semibold my-2">VII. RÉCLAMATIONS</h2>
            <p>
              Les réclamations afférentes à l'inscription à Bikeexplo.com, de
              Bike Explo Admin, Bike Explo Pro ainsi que l’application Bike
              Explo. et à leurs conditions d’utilisation peuvent être formulées
              soit directement en ligne par courrier électronique, soit par
              courrier adressé à : Soft Mobility Company - 1, bd Louis Salvator
              13006 Marseille - France
            </p>

            <h2 className="font-semibold my-2">VIII. MODIFICATIONS</h2>
            <p>
              Toute modification de ces conditions générales doit être réalisée
              par écrit entre les deux parties, par votre acceptation en ligne
              des termes mis à jour, ou, si vous poursuivez votre participation,
              à bikeexplo.com, après mise à jour de ces termes par Soft Mobility
              Company.
            </p>

            <h2 className="font-semibold my-2">IX. DISPOSITIONS GENERALES</h2>
            <p>
              En cas de non respect ou de non acceptation de ces conditions
              générales, Soft Mobility Company se réserve le droit d'exclure
              sans préavis un utilisateur enregistré du site Bikeexplo.com. Si
              l’une quelconque des stipulations des présentes conditions est
              tenue pour nulle et sans objet, elle sera réputée non écrite et
              n’entraînera pas la nullité des autres stipulations. Les présentes
              conditions générales sont régies par la loi française. En cas de
              litige, les seuls tribunaux de Marseille (13) seront compétents.
            </p>

            <p>
              L’offre intègre des Données et/ou Géoservices pour lesquels, soit
              Open Street Maps détient les droits de propriété intellectuelle,
              soit dispose de l’accord nécessaire des ayants droits. La licence
              concédée à l'utilisateur des Données ou Géoservices n'entraîne à
              son profit aucun transfert de propriété de ces données.
              L'utilisation de ces Données et/ou Géoservices est strictement
              limitée à l’Usage Privé de l’Utilisateur Final. Les Données et/ou
              Géoservices ne doivent pas être extraits de l’Offre, ou utilisés
              indépendamment de celle-ci. La reproduction pour diffusion à des
              tiers ou la commercialisation des sorties graphiques est
              interdite, sous quelque forme ou par quelque procédé que ce soit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
