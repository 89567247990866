import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SideBar from "../components/sideBar.components";
import axios from "axios";
import GeoTripsPoi from "../components/GeoTripsPoi/geotripspoi";

export default function OneParcoursClient() {
  const [trips, getTrip] = useState("");

  let { id } = useParams();

  useEffect(() => {
    axios("https://api.bikeexplo.com/api/trips/published/" + id)
      .then((response) => {
        getTrip(response.data);
      })
      .catch((error) => {
        console.log("Une erreur est survenue lors de la recherche" + error);
      });
  }, [id]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBar />
      <div className="flex flex-col gap-3 sm:gap-4 bg-bikeexplogray-dark w-full h-full m-2 md:m-4 overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row gap-1 sm:gap-2 h-10 lg:h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 h-5 lg:w-6 lg:h-6 "
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clipRule="evenodd"
            ></path>
          </svg>
          Parcours{" "}
        </h1>
        {/* Partie 1: Vignette, titre, description et client */}
        <div className="flex flex-row gap-3 sm:gap-5 justify-end h-2/6 sm:h-1/5">
          <div className="flex flex-col gap-3 w-1/2 sm:w-2/6 h-full">
            <div className="flex flex-col md:flex-row gap-1 sm:gap-2 w-full">
              <label className="text-white sm:text-left sm:w-1/2 text-xs md:text-base">
                Titre
              </label>
              <div className="rounded-lg relative block sm:w-1/2 px-3 py-2 bg-bikeexplogray-light placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base">
                {trips.title}
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-1 sm:gap-2 sm:h-3/6 w-full">
              <label className="block sm:text-left text-white sm:w-1/2 text-xs md:text-base">
                Description
              </label>
              <div className="h-20 lg:h-full overflow-auto self-start rounded-lg relative block w-full sm:w-1/2 px-3 py-2 bg-bikeexplogray-light placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base">
                {trips.description}
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-1/2 sm:w-3/6 gap-2">
            {trips && trips?.pictures[0] !== undefined && (
              <img
                src={`https://api.bikeexplo.com${trips.pictures[0].path}`}
                alt="vignette"
                className="w-20 h-28 lg:w-28 lg:h-48 rounded-lg"
              />
            )}
          </div>
        </div>

        {/*  Partie 2 : Catégories, Carte et Résumé du parcours */}
        <div className="flex flex-row w-full gap-3 sm:gap-5 h-1/6 sm:h-2/5 mt-2 md:mt-4">
          <div className="w-1/2 md:w-1/5 overflow-auto">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              {" "}
              Catégorie{" "}
            </h1>
            <div className="flex flex-col gap-2 bg-bikeexplogray-light rounded-b-lg p-2 w-full items-center">
              {trips.categories?.map((cat) => {
                return (
                  <div
                    className="rounded-lg bg-bikeexplogray-dark p-1 text-white text-center text-xs md:text-base w-full"
                    key={cat.id}
                  >
                    {cat.category}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="hidden sm:block md:w-3/5">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Carte
            </h1>
            <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2 h-48 sm:h-96 md:h-60 lg:h-64">
              <GeoTripsPoi />
            </div>
          </div>

          <div className="w-1/2 md:w-1/5 h-full">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Résumé du parcours
            </h1>
            <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2">
              <div className="flex flex-row gap-1 text-xs md:text-s lg:text-base">
                <label className="text-white w-2/3 break-words">
                  Durée (h):
                </label>
                <div className="w-1/3 border-bikeexplogray-dark rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-center text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10">
                  {trips.duration}
                </div>
              </div>
              <div className="flex flex-row gap-1 text-xs md:text-s lg:text-base">
                <label className="text-white w-2/3 break-words">
                  Difficulté:
                </label>
                <div className="w-1/3 border-bikeexplogray-dark rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-center text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10">
                  {trips.elevation}
                </div>
              </div>
              <div className="flex flex-row gap-1 text-xs md:text-s lg:text-base">
                <label className="text-white w-2/3 break-words">
                  Distance (km):
                </label>
                <div className="w-1/3 border-bikeexplogray-dark rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-center text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10">
                  {trips.distance}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Partie 3 : Etapes/POI */}
        <div className="self-center flex flex-col gap-2 w-full h-3/6 sm:h-2/5 mb-20">
          <div className="block sm:hidden w-full md:w-3/5">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Carte
            </h1>
            <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2 h-48 sm:h-full">
              <GeoTripsPoi />
            </div>
          </div>
          <div className="mb-20">
            <h2 className="text-white bg-salmon rounded-t-lg w-full h-9 text-center items-center flex justify-center text-xs md:text-base">
              {" "}
              Points d'intérêt sur le parcours
            </h2>
            <table className="bg-bikeexplogray-light w-full text-xs md:text-base">
              <tbody className="h-full">
                {trips.pointsOfInterest?.map((item, index) => {
                  return (
                    <tr
                      className="text-white even:bg-bikeexplogray-dark"
                      key={item.id}
                    >
                      <td className="text-center my-5 mx-2 h-8 sm:h-10">
                        <div className="text-start">{item?.title}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="w-full bg-salmon h-2 rounded-b-lg"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
