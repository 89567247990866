import React from "react";

const PaymentSuccess = () => {
  return (
    <div className="result-screen">
      <div className="text-center border-2 border-salmon rounded-lg p-8 flex flex-col gap-4">
        <h1 className="text-salmon font-semibold">Félicitations !</h1>
        <h1>Paiement réalisé avec succés</h1>
        <h2>Merci pour vos achats !</h2>
        <a
          href="/"
          className="text-salmon font-semibold underline-offset-2 flex flex-row gap-2"
        >
          <svg
            width="24"
            height="24"
            className="text-salmon"
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m21 12-8.25-7.875v4.5C4.855 8.625 3 14.286 3 19.875c2.279-2.918 4.294-4.5 9.75-4.5v4.5L21 12Z"></path>
          </svg>
          <p>Retour à la page d'accueil</p>
        </a>
      </div>
    </div>
  );
};

export default PaymentSuccess;
