import React, { useState, useEffect } from "react";
import SideBar from "../components/sideBar.components";
import jwt_decode from "jwt-decode";

export default function UpdateProfilClient({ data }) {
  const [formData, setFormData] = useState({});
  const [client, setClient] = useState(data);
  const [confirm, setConfirm] = useState({});

  async function updateClient(e) {
    e.preventDefault();
    setClient({ ...client, formData });

    var tok = localStorage.getItem("token");
    var decoded = jwt_decode(tok);
    var Id = decoded.id;

    var axios = require("axios");
    var data = formData;
    var config = {
      method: "put",
      url: "https://api.bikeexplo.com/api/clients/" + Id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const mess = "Votre compte a bien été modifié";
        setTimeout(function () {
          // after 1 seconds
          document.location.pathname = "/profil";
        }, 1000);
        setConfirm({ ...confirm, mess });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Show/Hide password
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    setConfirm({ ...confirm, formData });
    const password = formData.password;
    const confirmpassword = formData.confirmpassword;

    if (password === undefined || confirmpassword === undefined) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password === confirmpassword) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password !== confirmpassword) {
      const message = "Les mots de passe ne correspondent pas";
      setConfirm({ ...confirm, message });
      return message;
    }
  }, [formData]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBar />
      <div className="flex flex-col gap-2 w-full h-screen overflow-y-auto m-2">
        <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3 flex flex-row gap-2 h-10 sm:h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6"
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>{" "}
          Modifier mon profil{" "}
        </h1>{" "}
        <div className="bg-red-600 rounded-lg text-white text-xs w-full sm:text-base">
          {" "}
          {confirm.message}
        </div>
        <div className="bg-green-500 rounded-lg text-white text-xs w-full sm:text-base">
          {" "}
          {confirm.mess}
        </div>
        <form
          className="self-center w-full md:w-2/3 flex flex-col gap-2 mb-10"
          //enctype="multipart/form-data"
          onSubmit={updateClient}
        >
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Nom d'utilisateur
              </label>
              <input
                id="username"
                name="username"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-nonerelative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white rounded-t-lg focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau nom d'utilisateur"
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                name="email"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouvel email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="phone-number" className="sr-only">
                Numéro de téléphone
              </label>
              <input
                id="phone-number"
                name="phone"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau numéro de téléphone"
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Mot de passe
              </label>
              <input
                id="password"
                name="password"
                type={passwordShown ? "text" : "password"}
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau mot de passe (une majuscule, un chiffre et un caractère spécial)"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              <button
                className="absolute inset-y-0 right-0 pr-2 text-salmon hover:text-bikeexplogray-light"
                onClick={togglePassword}
              >
                <svg
                  className="w-5 sm:w-6 h-5 sm:h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Confirmation du nouveau mot de passe
              </label>
              <input
                id="confirmpassword"
                name="password"
                type="password"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Confirmation du nouveau mot de passe"
                onChange={(e) =>
                  setFormData({ ...formData, confirmpassword: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="store-name" className="sr-only">
                Nouveau nom du magasin
              </label>
              <input
                id="store-name"
                name="store-name"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau nom du magasin"
                onChange={(e) =>
                  setFormData({ ...formData, storeName: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="website" className="sr-only">
                Nouveau site web
              </label>
              <input
                id="website"
                name="website"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau site web"
                onChange={(e) =>
                  setFormData({ ...formData, website: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="booking link" className="sr-only">
                Nouveau lien de réservation
              </label>
              <input
                id="booking-link"
                name="booking-link"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau lien de réservation"
                onChange={(e) =>
                  setFormData({ ...formData, bookingLink: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="description" className="sr-only">
                Nouvelle description
              </label>
              <input
                id="description"
                name="description"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouvelle description"
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="address" className="sr-only">
                Nouvelle adresse
              </label>
              <input
                id="address"
                name="address"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouvelle adresse (N° et nom de rue)"
                onChange={(e) =>
                  setFormData({ ...formData, adress: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="zipcode" className="sr-only">
                Nouveau code postal
              </label>
              <input
                id="zipcode"
                name="zipcode"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau code postal"
                onChange={(e) =>
                  setFormData({ ...formData, zipcode: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="city" className="sr-only">
                Nouvelle ville
              </label>
              <input
                id="city"
                name="city"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouvelle ville"
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="siret" className="sr-only">
                Nouveau numéro SIRET
              </label>
              <input
                id="siret"
                name="siret"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau numéro SIRET"
                onChange={(e) =>
                  setFormData({ ...formData, siret: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="TVA" className="sr-only">
                Nouveau numéro TVA
              </label>
              <input
                id="TVA"
                name="TVA"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-b-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nouveau numéro de TVA"
                onChange={(e) =>
                  setFormData({ ...formData, TVA: e.target.value })
                }
              />
            </div>
            <div className="hidden">
              <label htmlFor="TVA" className="sr-only">
                Nouvel extrait KBIS
              </label>
              <input
                id="KBIS"
                name="Kbis"
                type="file"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white rounded-b-lg focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Extrait Kbis"
                onChange={(e) =>
                  setFormData({ ...formData, Kbis: e.target.value })
                }
              />
            </div>
          </div>

          <button
            type="submit"
            className="text-xs md:text-base w-full p-1 bg-salmon text-sm font-medium rounded-lg text-white border-2 border-salmon hover:bg-white hover:text-salmon focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
          >
            Confirmer
          </button>
        </form>
      </div>
    </div>
  );
}
