import React, { useState, useEffect } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import LogoTransp from "./logoBEtransp.png";

// ERRORS FORM
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function RegistrationClient({ data }) {
  const [formData, setFormData] = useState({});
  const [client, setClient] = useState(data);
  const [confirm, setConfirm] = useState({});
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  // ERRORS VALIDATIONS
  const URL =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  let schema = yup.object().shape({
    email: yup
      .string()
      .email("Vous devez rentrer un email valide.")
      .required("L'email est requis."),
    password: yup
      .string()
      .min(8, "Le mot de passe doit comporter au minimum 8 caractères.")
      .required("Le mot de passe est requis."),
    username: yup
      .string()
      .min(6, "Le nom d'utilisateur doit comporter au minimum 6 caractères.")
      .required("Le nom d'utilisateur est requis"),
    phone: yup
      .number()
      .typeError("Le numéro de téléphone ne doit comporter que des chiffres")
      .min(10, "Le numéro de téléphone doit comporter 10 chiffres.")
      .required("Le numéro de téléphone est requis."),
    address: yup.string().required("L'adresse est requise."),
    storeName: yup.string().required("Le nom du magasin est requis."),
    website: yup
      .string()
      .matches(URL, "Le site web n'est pas valide.")
      .required("Le site web est requis."),
    city: yup.string().required("La ville est requise."),
    zipcode: yup
      .number()
      .typeError("Le code postal ne doit comporter que des chiffres")
      .required("Le code postal est requis"),
    siret: yup
      .number()
      .typeError("Le numéro SIRET ne doit comporter que des chiffres")
      .min(14, "Le numéro SIRET doit contenir 14 caractères.")
      .required("Le numéro SIRET est requis"),
    TVA: yup
      .string()
      .min(13, "Le numéro de TVA doit comporter 13 caractères.")
      .required("Le numéro de TVA est requis."),
  });

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  //Show/Hide password
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  async function registration(e) {
    e.preventDefault();
    setClient({ ...client, formData });

    const password = formData.password;
    const confirmpassword = formData.confirmpassword;

    var axios = require("axios");
    var data = formData;
    var config = {
      method: "post",
      url: "https://api.bikeexplo.com/api/clients",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    if (password === confirmpassword && agree) {
      axios(config)
        .then(function (response) {
          const mess = "Votre compte a été crée";
          setTimeout(function () {
            // after 2 seconds
            document.location.pathname = "/connexion";
          }, 3000);
          setConfirm({ ...confirm, mess });
        })
        .catch(function (error) {
          console.log(error);
          setError(
            "Une erreur s'est produite, veuillez vérifier que tous les champs sont renseignés correctement."
          );
        });
    } else {
      setError("Vous n'avez pas accepté les conditions générales de vente");
    }
  }

  async function handleAgreement(e) {
    setAgree(true);
    setShowModal(false);
  }

  useEffect(() => {
    setConfirm({ ...confirm, formData });
    const password = formData.password;
    const confirmpassword = formData.confirmpassword;

    if (password === undefined || confirmpassword === undefined) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password === confirmpassword) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password !== confirmpassword) {
      const message = "Les mots de passe ne correspondent pas";
      setConfirm({ ...confirm, message });
      return message;
    }
  }, [formData]);

  return (
    <div className="bg-bikeexplogray-dark min-h-full h-screen w-screen flex items-center justify-center py-8 px-4 sm:px-6 lg:px-8 overflow-y-auto">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-44 sm:h-60 w-auto"
            src={LogoTransp}
            alt="Workflow"
          />
          <div className="bg-green-400 rounded-lg text-white text-center w-full ">
            {" "}
            {confirm.mess}{" "}
          </div>
          <div className="bg-red-600 rounded-lg text-white text-center w-full ">
            {" "}
            {error}{" "}
          </div>
          {errors.username && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.username?.message}
            </p>
          )}
          {errors.email && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.email?.message}
            </p>
          )}
          {errors.phone && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.phone?.message}
            </p>
          )}
          {errors.storeName && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.storeName?.message}
            </p>
          )}
          {errors.website && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.website?.message}
            </p>
          )}
          {errors.adress && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.adress?.message}
            </p>
          )}
          {errors.zipcode && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.zipcode?.message}
            </p>
          )}
          {errors.city && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.city?.message}
            </p>
          )}
          {errors.siret && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.siret?.message}
            </p>
          )}
          {errors.TVA && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.TVA?.message}
            </p>
          )}
          {errors.password && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
              {errors.password?.message}
            </p>
          )}

          <h2 className="mt-3 sm:mt-6 text-center text-base sm:text-2xl font-extrabold text-white">
            Inscrivez-vous à Bike Explo Pro
          </h2>
        </div>
        <p className="bg-red-600 rounded-lg text-white text-xs w-full text-base">
          {" "}
          {confirm.message}
        </p>
        <input type="hidden" name="remember" value="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="username" className="sr-only">
              Nom d'utilisateur
            </label>
            <input
              id="username"
              {...register("username")}
              name="username"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-t-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Nom d'utilisateur (minimum 6 caractères)"
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email
            </label>
            <input
              id="email-address"
              {...register("email")}
              name="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="phone-number" className="sr-only">
              Numéro de téléphone
            </label>
            <input
              id="phone-number"
              {...register("phone")}
              name="phone"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Numéro de téléphone"
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="store-name" className="sr-only">
              Nom du magasin
            </label>
            <input
              id="store-name"
              {...register("storeName")}
              name="store-name"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Nom du magasin"
              onChange={(e) =>
                setFormData({ ...formData, storeName: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="website" className="sr-only">
              site web
            </label>
            <input
              id="website"
              {...register("website")}
              name="website"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Site web (www.monsiteweb.com)"
              onChange={(e) =>
                setFormData({ ...formData, website: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="address" className="sr-only">
              Adresse
            </label>
            <input
              id="address"
              {...register("address")}
              name="address"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Adresse (N° et nom de rue)"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="zipcode" className="sr-only">
              Code postal
            </label>
            <input
              id="zipcode"
              {...register("zipcode")}
              name="zipcode"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Code postal"
              onChange={(e) =>
                setFormData({ ...formData, zipcode: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="city" className="sr-only">
              Ville
            </label>
            <input
              id="city"
              {...register("city")}
              name="city"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Ville"
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="siret" className="sr-only">
              Numéro SIRET
            </label>
            <input
              id="siret"
              {...register("siret")}
              name="siret"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Numéro SIRET"
              onChange={(e) =>
                setFormData({ ...formData, siret: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="siret" className="sr-only">
              Numéro TVA
            </label>
            <input
              id="TVA"
              {...register("TVA")}
              name="TVA"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Numéro TVA"
              onChange={(e) =>
                setFormData({ ...formData, TVA: e.target.value })
              }
            />
          </div>
          <div className="flex flex-row relative">
            <label htmlFor="password" className="sr-only">
              Mot de passe
            </label>
            <input
              id="password"
              {...register("password")}
              name="password"
              type={passwordShown ? "text" : "password"}
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-xstext-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs sm:text-sm"
              placeholder="Mot de passe (avec majuscule, chiffre et caractères spéciaux)"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            <button
              className="absolute inset-y-0 right-0 pr-2 text-salmon hover:text-bikeexplogray-light"
              onClick={togglePassword}
            >
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Confirmation du mot de passe
            </label>
            <input
              id="confirmpassword"
              name="confirmpassword"
              type="password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-b-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
              placeholder="Confirmation du mot de passe"
              onChange={(e) =>
                setFormData({ ...formData, confirmpassword: e.target.value })
              }
            />
          </div>
          <div>
            <button
              className="text-white hover:text-salmon text-xs md:text-s my-4"
              onClick={() => setShowModal(true)}
            >
              Consulter et accepter les Conditions générales de vente
            </button>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4  mt-2 bg-salmon text-xs sm:text-sm font-medium rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
            onClick={registration}
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-white group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            S'inscrire
          </button>
          <div
            className={
              showModal
                ? "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-full bg-bikeexplogray-dark bg-opacity-75 flex justify-center items-center"
                : "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-full bg-bikeexplogray-dark bg-opacity-75 justify-center items-center"
            }
          >
            <div className="w-3/5 sm:w-2/5 flex flex-col gap-2 p-3 bg-bikeexplogray-dark rounded-lg border-2 border-salmon">
              <button onClick={() => setShowModal(false)} className="self-end">
                <svg
                  className="w-4 sm:w-6 h-4 sm:h-6 border border-salmon rounded-full"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <div className="w-auto">
                <div className="flex flex-col text-justify text-white h-96 overflow-y-auto">
                  <h1 className="text-center my-4 font-bold text-salmon">
                    Conditions Générales de Vente de services
                  </h1>
                  <h2 className="font-semibold my-2">
                    ARTICLE 1 - CHAMP D'APPLICATION
                  </h2>
                  <p>
                    Les présentes Conditions Générales de Vente, ci-après « CGV
                    », s'appliquent, sans restriction ni réserve à tout achat de
                    services proposés par la Société Soft Mobility Company,
                    ci-après désignée le « Prestataire », aux clients
                    professionnels, ci-après désignés le «Client», sur son site
                    Internet www.bikeexplo.com et l’application mobile Bike
                    Explo, désignés ci-après le Site. Les coordonnées du
                    Prestataire sont les suivantes : Soft Mobility Company SAS
                    au capital de 10 000 euros Siège social : 1, bd Louis
                    Salvator 13006 Marseille RCS Marseille 881 312 557
                  </p>
                  <p>
                    Le Client déclare avoir pris connaissance des présentes
                    Conditions Générales de Ventes et les avoir acceptées avant
                    la mise en œuvre de la procédure de commande ainsi que des
                    conditions générales d'utilisation du Site. Sauf preuve
                    contraire, les données enregistrées par le Prestataire
                    constituent la preuve de l'ensemble des transactions passées
                    avec le Client via le Site. La validation de la commande de
                    Services par le Client vaut acceptation sans restriction ni
                    réserve des présentes Conditions Générales de Vente. Le
                    Client reconnaît avoir la capacité requise pour contracter
                    et acquérir les Services proposés, ces Conditions Générales
                    de Vente pouvant faire l'objet de modifications ultérieures.
                    En cas de fourniture de prestations de service à l'étranger,
                    il convient de se renseigner, le cas échéant, sur les lois
                    applicables dans les pays visés, de veiller au respect des
                    contraintes éventuelles qu'elles imposent et de traduire les
                    conditions de vente dans les langues appropriées.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 2 - SERVICES ET PRODUITS PROPOSÉS{" "}
                  </h2>
                  <p>
                    Le Prestataire propose sur l’application Bike Explo, des
                    Parcours vélo et des services en découlant: Trois types
                    principaux de Parcours existent :
                  </p>
                  <ul>
                    <li>
                      Les Parcours “sportifs” sont définis comme suit : Parcours
                      comprenant seulement une trace GPS ou des points GPS (GPX,
                      KML…).
                    </li>
                    <li>
                      Les Parcours “touristiques” sont définis comme suit :
                      Parcours comprenant seulement une trace GPS ou des points
                      GPS (GPX, KML…), des contenus textuels et photos liés aux
                      points d'intérêt.{" "}
                    </li>
                    <li>
                      Les Parcours “événementiel” sont définis comme suit :
                      Parcours comprenant seulement une trace GPS ou des points
                      GPS (GPX, KML…), des contenus textuels et photos liés aux
                      points d'intérêt. Parcours temporaire ou pour un événement
                      à une date définie.
                    </li>{" "}
                  </ul>
                  <p>
                    Pour chaque type de Parcours, le Client à l’obligation de
                    fournir au Prestataire une trace GPS vérifiée et sécurisée
                    ou des points GPS permettant de créer une trace vérifiée et
                    sécurisée. Les contenus textuels et visuels de chaque type
                    de parcours peuvent être fournis par le Client.
                  </p>
                  <p>
                    Le Prestataire propose la création de contenu textuel et
                    visuel sur des points d'intérêt et, plus globalement, la
                    rédaction de Parcours à partir d’une trace GPS ou de points
                    GPS.
                  </p>
                  <p>
                    Le Prestataire met à disposition des Clients un kit de
                    démarrage comprenant : Un sticker Bike Explo à coller sur
                    vitrine. 100 flyers Bike Explo. Les frais de création de
                    compte client.
                  </p>
                  <h2 className="font-semibold my-2">ARTICLE 3 - COMMANDE</h2>
                  <p>
                    Ces parcours sont accessibles au grand public par le biais
                    d’un code que le Client lui donne.
                  </p>
                  <p>
                    Pour la création du code les étapes sont les suivantes :
                  </p>
                  <ul>
                    <li>Etape 1 : Connexion sur le site pro.bikeexplo.com </li>
                    <li>Etape 2 : Sélection du parcours </li>
                    <li>Etape 3 : Validation </li>
                    <li>
                      Etape 4 : Apparition du code dans une fenêtre pop up
                    </li>
                    <li>
                      Etape 5 : Reporting des codes utilisés dans “chiffres
                      clés”
                    </li>
                  </ul>
                  <p>
                    Il appartient au Client de vérifier l'exactitude de la
                    commande et de signaler immédiatement toute erreur. Le
                    Prestataire se réserve le droit d'annuler ou de refuser
                    toute commande d'un Client avec lequel il existerait un
                    litige relatif au paiement d'une commande antérieure.
                  </p>
                  <h2 className="font-semibold my-2">ARTICLE 4 - TARIFS</h2>
                  <p>
                    Les codes d’utilisation des Parcours proposés par le
                    Prestataire sont fournis aux tarifs en vigueur sur le Site,
                    lors de l'enregistrement de la commande par le Prestataire.
                    Les prix sont exprimés en Euros TTC. Ces tarifs sont fermes
                    et non révisables pendant leur période de validité, telle
                    qu'indiqué sur le Site, le Prestataire se réservant le
                    droit, hors cette période de validité, de modifier les prix
                    à tout moment. Le kit de démarrage proposé par le
                    Prestataire sont fournis aux tarifs en vigueur selon les
                    conditions définies en Annexe. La création de contenu
                    textuel et visuel proposée par le Prestataire est fournie
                    aux tarifs en vigueur selon les conditions définies en
                    Annexe.{" "}
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 5 - CONDITIONS DE PAIEMENT
                  </h2>
                  <p>
                    Le paiement par le Client des Services tel que définis à
                    l'article 2 (Kit de démarrage, création de contenu), à
                    l'exception des codes d’utilisation, sera réalisé par
                    virement bancaire suite à la facturation une fois le Service
                    réalisé par le Prestataire. La facturation des codes
                    d’utilisation des Parcours est effectuée a posteriori selon
                    le rapport d’utilisation visible dans les chiffres clés sur
                    le site pro.bikeexplo.com le dernier jour du mois. Dans ce
                    cadre, les paiements seront réalisés par virement bancaire
                    par le biais d’une facturation mensuelle. Le Client règlera
                    alors le montant des sommes dûes dans un délai de 15 jours à
                    compter de la date de la facture du Prestataire. Seules les
                    informations nécessaires à la facturation sont conservées
                    par le Prestataire. Les paiements effectués par le Client ne
                    seront considérés comme définitifs qu'après encaissement
                    effectif des sommes dues par le Prestataire. En outre, le
                    Prestataire se réserve le droit, en cas de non-respect des
                    conditions de paiement figurant ci-dessus, de suspendre ou
                    d'annuler la fourniture des Services commandés par le Client
                    et/ou de suspendre l'exécution de ses obligations. Aucun
                    frais supplémentaire, supérieur aux coûts supportés par le
                    Prestataire pour l'utilisation d'un moyen de paiement ne
                    pourra être facturé au Client.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 6 - FOURNITURE DES PRESTATIONS
                  </h2>
                  <p>
                    Les Services commandés par le Client seront fournis dans un
                    délai de 2 semaines à partir de la réception de l’ensemble
                    des éléments nécessaires à la création d’un parcours. Le
                    Prestataire s'engage à faire ses meilleurs efforts pour
                    fournir les Services commandés par le Client, dans le cadre
                    d'une obligation de moyens et dans les délais ci-dessus
                    précisés. Si les services commandés n'ont pas été fournis
                    dans un délai de 2 semaines à partir de la réception de
                    l’ensemble des éléments nécessaires à la création d’un
                    parcours, pour toute autre cause que la force majeure ou le
                    fait du Client, la vente pourra être résolue à la demande
                    écrite du Client dans les conditions prévues aux articles L.
                    216-2, L. 216-3 et L. 241-4 du Code de la consommation. Les
                    sommes versées par le Client lui seront alors restituées au
                    plus tard dans les quatorze jours qui suivent la date de
                    dénonciation du contrat, à l'exclusion de toute
                    indemnisation ou retenue.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 7 - RESPONSABILITE DU PRESTATAIRE - GARANTIE
                  </h2>
                  <p>
                    Le Prestataire garantit, conformément aux dispositions
                    légales et sans paiement complémentaire, le Client, contre
                    tout défaut de conformité ou vice caché, provenant d'un
                    défaut de conception ou de réalisation des Services
                    commandés dans les conditions et selon les modalités
                    définies en annexe aux présentes Conditions Générales de
                    Vente. Afin de faire valoir ses droits, le Client devra
                    informer le Prestataire, par écrit, de l'existence des vices
                    ou défauts de conformité dans un délai maximum de 10 jours à
                    compter de la fourniture des Services. Le Prestataire
                    remboursera, rectifiera ou fera rectifier dans la mesure du
                    possible les services jugés défectueux dans les meilleurs
                    délais et au plus tard dans les 15 jours suivant la
                    constatation par le Prestataire du défaut ou du vice. Le
                    remboursement s'effectuera par crédit sur le compte bancaire
                    du Client ou par chèque bancaire adressé au Client. La
                    garantie du Prestataire est limitée au remboursement des
                    Services effectivement payés par le Client et le Prestataire
                    ne pourra être considéré comme responsable ni défaillant
                    pour tout retard ou inexécution consécutif à la survenance
                    d'un cas de force majeure habituellement reconnu par la
                    jurisprudence française. Les Services fournis par
                    l'intermédiaire du Site du Prestataire sont conformes à la
                    réglementation en vigueur en France. La responsabilité du
                    Prestataire ne saurait être engagée en cas de non respect de
                    la législation du pays dans lequel les Services sont
                    fournis, qu'il appartient au Client, qui est seul
                    responsable du choix des Services demandés, de vérifier.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 8 - INFORMATIQUES ET LIBERTÉS
                  </h2>
                  <p>
                    En application du Règlement Européen 2016/679 retranscrit
                    par la Loi du 20 Juin 2018 numéro 2018-493 relatif à la
                    protection des données personnelles, il est rappelé que les
                    données nominatives qui sont demandées au Client sont
                    nécessaires au traitement de sa commande et à
                    l'établissement des factures, notamment. Ces données peuvent
                    être communiquées aux éventuels partenaires du Prestataire
                    chargés de l'exécution, du traitement, de la gestion et du
                    paiement des commandes. Le Client dispose, conformément aux
                    réglementations nationales et européennes en vigueur d'un
                    droit d'accès permanent, de modification, de rectification
                    et d'opposition s'agissant des informations le concernant.
                    Ce droit peut être exercé dans les conditions et selon les
                    modalités définies sur le Site. Pour plus de précisions,
                    veuillez vous référer à la Politique de Confidentialité de
                    Soft Mobility Company.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 9 - PROPRIETE INTELLECTUELLE
                  </h2>
                  <p>
                    Le contenu de l’application est la propriété de Soft
                    Mobility Company et de ses partenaires et est protégé par
                    les lois françaises et internationales relatives à la
                    propriété intellectuelle. Toute reproduction totale ou
                    partielle de ce contenu est strictement interdite et est
                    susceptible de constituer un délit de contrefaçon. En outre,
                    le Prestataire reste propriétaire de tous les droits de
                    propriété intellectuelle sur les photographies,
                    présentations, études, dessins, modèles, prototypes, etc,
                    réalisés, même à la demande du Client, en vue de la
                    fourniture des Services au Client. Le Client s'interdit donc
                    toute reproduction ou exploitation desdites études, dessins,
                    modèles et prototypes, etc, sans l'autorisation expresse,
                    écrite et préalable du Prestataire qui peut la conditionner
                    à une contrepartie financière.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 10 - DROIT APPLICABLE - LANGUE
                  </h2>
                  <p>
                    Les présentes Conditions Générales de Vente et les
                    opérations qui en découlent sont régies et soumises au droit
                    français. Les présentes Conditions Générales de Vente sont
                    rédigées en langue française. Dans le cas où elles seraient
                    traduites en une ou plusieurs langues étrangères, seul le
                    texte français ferait foi en cas de litige.
                  </p>
                  <h2 className="font-semibold my-2">ARTICLE 11 - LITIGES</h2>
                  <p>
                    Tous les litiges auxquels les opérations d'achat et de vente
                    conclues en application des présentes conditions générales
                    de vente pourraient donner lieu, concernant tant leur
                    validité, leur interprétation, leur exécution, leur
                    résiliation, leurs conséquences et leurs suites et qui
                    n'auraient pu être résolues entre le vendeur et le client
                    seront soumis aux tribunaux compétents dans les conditions
                    de droit commun. Le Client est informé qu'il peut en tout
                    état de cause recourir à une médiation conventionnelle,
                    notamment auprès de la Commission de la médiation de la
                    consommation (C. consom. art. L. 612-1) ou auprès des
                    éventuelles instances de médiation sectorielles existantes,
                    ou à tout mode alternatif de règlement des différends
                    (conciliation, par exemple) en cas de contestation.
                  </p>
                  <h2 className="font-semibold my-2">
                    ARTICLE 12 - INFORMATION PRÉCONTRACTUELLE - ACCEPTATION DU
                    CLIENT
                  </h2>
                  <p>
                    Le Client reconnaît avoir eu communication, préalablement à
                    la passation de sa commande et à la conclusion du contrat,
                    d'une manière lisible et compréhensible, des présentes
                    Conditions Générales de Vente et de toutes les informations
                    listées à l'article L. 221-5 du Code de la consommation, et
                    notamment les informations suivantes : Les caractéristiques
                    essentielles des Services, compte tenu du support de
                    communication utilisé et du Service concerné. Le prix des
                    Services et des frais annexes. En l'absence d'exécution
                    immédiate du contrat, la date ou le délai auquel le
                    Prestataire s'engage à fournir les Services commandés. Les
                    informations relatives à l'identité du Prestataire, à ses
                    coordonnées postales, téléphoniques et électroniques. Les
                    informations relatives aux garanties légales et
                    contractuelles et à leurs modalités de mise en œuvre. La
                    possibilité de recourir à une médiation conventionnelle en
                    cas de litige. Les informations relatives au droit de
                    rétractation (existence, conditions, délai, modalités
                    d'exercice de ce droit et formulaire type de rétractation),
                    aux modalités de résiliation et autres conditions
                    contractuelles importantes. Les moyens de paiement acceptés.
                  </p>
                  <h1 className="font-bold">
                    Annexe Conditions Générales de Vente{" "}
                  </h1>
                  <h2 className="font-semibold my-2">Tarification</h2>
                  <p>Le Kit de démarrage comprend :</p>
                  <ul>
                    <li>Un sticker Bike Explo à coller sur vitrine.</li>
                    <li>100 flyers Bike Explo. </li>
                    <li>Les frais de création de compte client.</li>
                  </ul>
                  <p>
                    Le Kit de démarrage est facturé 100€ HT dès la signature des
                    conditions particulières par le Client. Dans le cas où le
                    Loueur n’a pas fourni les éléments d’un Parcours, la
                    tarification de la Création de contenu par le Prestataire
                    est définie selon le nombre de points d'intérêt du Parcours.
                  </p>{" "}
                  <p>La tarification suit le modèle suivant : </p>
                  <table className="border">
                    <thead className="border">
                      <tr className="border">
                        <th className="border">Nombre de Points d'intérêt</th>{" "}
                        <th className="border">Prix du contenu texte HT</th>{" "}
                        <th className="border">Prix du contenu visuel* HT</th>{" "}
                        <th className="border">Prix total du Parcours HT</th>
                      </tr>
                    </thead>{" "}
                    <tbody className="border text-center">
                      <tr className="border">
                        <td className="border">Inférieur à 10</td>{" "}
                        <td className="border">150€</td>{" "}
                        <td className="border">50€</td>{" "}
                        <td className="border">200€ </td>
                      </tr>
                      <tr className="border">
                        <td className="border">Entre 10 et 15</td>{" "}
                        <td className="border">225€</td>{" "}
                        <td className="border">75€</td>{" "}
                        <td className="border">300€</td>{" "}
                      </tr>
                      <tr className="border">
                        <td className="border">Entre 15 et 20</td>{" "}
                        <td className="border">300€</td>{" "}
                        <td className="border">125€</td>{" "}
                        <td className="border">425€</td>{" "}
                      </tr>
                      <tr className="border">
                        <td className="border">Supérieur à 20</td>{" "}
                        <td className="border">425€</td>{" "}
                        <td className="border">175€ </td>
                        <td className="border">600€</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>*hors licence d’exploitation</p>
                  <p>
                    Dans ce cadre, les licences d’exploitation affiliées aux
                    contenus visuels achetés par le Prestataire seront facturées
                    au Client.{" "}
                  </p>
                </div>
              </div>

              <button
                className="group relative w-full flex justify-center mt-4 py-2 px-4 bg-salmon text-xs sm:text-sm font-medium rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
                onClick={handleAgreement}
              >
                Accepter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
