import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Confetti from "react-confetti";

export default function CodeGeneratePage() {
  // STATE
  const [trips, setTrips] = useState([]);
  const [showModal, setShowModal] = useState({ visible: false, data: null });
  const [client, setClient] = useState({});
  const [selectedTrip, setSelectedTrip] = useState(null);

  // VARS
  var tok = localStorage.getItem("token");
  var decoded = jwt_decode(tok);
  var Id = decoded.id;

  useEffect(() => {
    const fethTrips = async () => {
      const trip = await axios(
        `https://api.bikeexplo.com/api/trips/client/${Id}`
      );
      setTrips(trip.data);
    };
    fethTrips();

    const fetchClient = async () => {
      const client = await axios(`https://api.bikeexplo.com/api/clients/${Id})`);
      setClient(client.data);
    };
    fetchClient();
  }, [Id]);

  function generate() {
    if (client?.credit <= 0 || selectedTrip === null)
      return;

    // FUNCTION
    async function getTripCounterValue() {
      const res = await axios
        .get(urlTrip)
        .then((response) => {
          return response.data.code_counter;
        })
        .catch((error) => console.log(error));
      return res + 1;
    }

    async function getClientCounterValue() {
      const res = await axios
        .get(urlClient)
        .then((response) => {
          return response.data.code_counter;
        })
        .catch((error) => console.log(error));
      return res + 1;
    }

    async function getClientCreditValue() {
      const res = await axios
        .get(urlClient)
        .then((response) => {
          return response.data.credit;
        })
        .catch((error) => console.log(error));
      return res > 0 ? res - 1 : 0;
    }

    // REQUEST DATABASE CLIENT CODE_COUNTER
    function CodeClient() {
      let clientCounter = getClientCounterValue();
      clientCounter.then((res) => {
        axios
          .put(urlClient, {
            code_counter: res,
          })
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          });
      });
    }

    // REQUEST DATABASE TRIP CODE
    function CodeTrip() {
      let tripCounter = getTripCounterValue();
      tripCounter.then((res) => {
        axios
          .put(urlTrip, {
            code_counter: res,
          })
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          });
      });

      axios
        .post(urlCode, {
          code: str,
          tripId: selectedTrip,

          //code_counter: res,
        })
        .then((response) => {})
        .catch((err) => {
          console.log(err);
        });
    }

    // VARS
    let str = Math.random().toString(36).substr(2, 5).toUpperCase();
    const urlCode = "https://api.bikeexplo.com/api/code";
    const urlTrip = `https://api.bikeexplo.com/api/trips/${selectedTrip}`;
    const urlClient = `https://api.bikeexplo.com/api/clients/${Id}`;

    // REQUEST DATABASE
    if (selectedTrip) {
      CodeClient();
      CodeTrip();
      setShowModal({ visible: true, data: str });
    }

    let creditValue = getClientCreditValue();

    creditValue.then((res) => {
      axios
        .put(urlClient, {
          credit: res,
        })
        .then((response) => {})
        .catch((err) => {
          console.log(err);
        });
    });
  }

  return (
    <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-3/4 bg-bikeexplogray-dark">
      <select
        className="w-11/12 p-2 rounded-full bg-salmon text-white text-xs sm:text-base border-none focus:outline-none focus:ring-0"
        id="trips"
        value={selectedTrip}
        onChange={(e) => setSelectedTrip(e.target.value)}
      >
        <option className="text-xs md:text-sm" value={null}>
          Sélectionner un parcours{" "}
        </option>
        {trips.map((trip) => (
          <option key={trip.id} value={trip.id}>
            {trip.id} : {trip.title}
          </option>
        ))}
      </select>
      <button
        className="w-11/12 sm:w-5/12 p-2 text-white text-xs sm:text-base bg-salmon rounded-full hover:bg-bikeexplogray-light"
        onClick={generate}
        disabled={selectedTrip === null || client.credit <= 0}
        title="Consultez le nombre de codes qu'il vous reste à déclencher sur votre page profil ou sur l'onglet 'Chiffres clés'"
      >
        Générer un code
      </button>
      <div
        className={
          showModal.visible
            ? "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-full bg-bikeexplogray-dark bg-opacity-75 flex justify-center items-center"
            : "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-full bg-bikeexplogray-dark bg-opacity-75 justify-center items-center"
        }
      >
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={1000}
        />
        <div className="w-3/5 sm:w-2/5 flex flex-col gap-2 p-3 bg-bikeexplogray-dark rounded-lg border-2 border-salmon">
          <button
            onClick={() => setShowModal({ visible: false, data: null })}
            className="self-end"
          >
            <svg
              className="w-4 sm:w-6 h-4 sm:h-6 border border-salmon rounded-full"
              fill="none"
              stroke="salmon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <div className="text-white font-semibold text-xs sm:text-base text-center flex items-center justify-center">
            Code à donner à vos clients pour déverouiller le parcours :
          </div>

          <div className="text-white flex items-center justify-center text-sm sm:text-xl border-2 border-salmon rounded-lg">
            {showModal.data}
          </div>
          <div className="text-red-600 font-semibold flex justify-center text-xs sm:text-base">
            ATTENTION : Il s'agit d'un code à usage unique, il se supprimera une
            fois qu'il aura été utilisé par votre client.
          </div>
        </div>
      </div>
    </div>
  );
}
