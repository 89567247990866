import React, { useState, useEffect } from "react";
import SideBar from "../components/sideBar.components";
import jwt_decode from "jwt-decode";
import axios from "axios";
import UploadLogo from "../components/uploadLogo.components";
// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";

export default function ProfilClient() {
  const [popup, setPopup] = useState(false);

  if (localStorage.getItem("token")) {
    var tok = localStorage.getItem("token");
    var decoded = jwt_decode(tok);
    var Id = decoded.id;
  }

  const queryKey = ["client"];
  const getClients = async () => {
    const { data } = await axios.get(
      `https://api.bikeexplo.com/api/clients/${Id}`
    );
    return data;
  };

  const useClient = () => useQuery(queryKey, getClients);
  const {
    data: client,
    isSuccess: clientIsSuccess,
    isLoading: clientIsLoading,
    isError: clientIsError,
  } = useClient();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `https://api.bikeexplo.com/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(queryKey);
      },
    }
  );

  if (localStorage.getItem("token")) {
    return (
      <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
        <SideBar />
        <div className="flex flex-col gap-2 lg:gap-4 w-full h-full overflow-y-auto m-2 lg:m-4">
          <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3 flex flex-row gap-2 h-10 sm:h-12 w-full items-center text-xs md:text-base">
            <svg
              className="w-5 sm:w-6 h-5 sm:h-6"
              fill="none"
              stroke="salmon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              ></path>
            </svg>{" "}
            Mon profil{" "}
          </h1>

          <div className="flex flex-row gap-2 lg:gap-4 lg:my-4">
            <div className="flex flex-col gap-2 lg:gap-4 w-1/2 sm:w-1/3">
              <div className="">
                <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
                  Mes infos
                </h2>
                <div className="flex flex-col gap-2 rounded-b-lg bg-bikeexplogray-light p-2 sm:p-3 text-white text-xs md:text-base">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <label className="text-xs sm:text-base font-semibold sm:w-1/2">
                      Nom d'utilisateur :{" "}
                    </label>
                    <div className=" text-xs sm:text-base sm:w-1/2">
                      {client?.username}
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between">
                    <label className=" text-xs sm:text-base font-semibold sm:w-1/2">
                      Email :{" "}
                    </label>
                    <div className="text-xs sm:text-base sm:w-1/2">
                      {" "}
                      {client?.email}
                    </div>
                  </div>
                  <div className="text-center">
                    <a href="/majprofil">
                      {" "}
                      <button className="text-xs sm:text-base p-1 sm:p-2 rounded-full bg-salmon text-center shadow-xl border-2 border-salmon hover:bg-white hover:text-salmon">
                        Modifier
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="">
                <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
                  Les codes
                </h2>
                <div className="flex flex-col gap-2 rounded-b-lg bg-bikeexplogray-light p-2 sm:p-3 text-white text-xs md:text-base">
                  <div className="text-white flex flex-row gap-2 text-center justify-center">
                    Il vous reste
                    <div className="text-salmon font-semibold">
                      {client?.credit}
                    </div>
                    code(s) à déclencher.
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/2 sm:w-2/3">
              <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
                Mon entreprise
              </h2>
              <div className="rounded-b-lg flex flex-col gap-2 bg-bikeexplogray-light p-2 sm:p-3 text-white">
                <div className="text-xs md:text-base">{client?.logo}</div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2">
                    Nom :
                  </label>{" "}
                  <div className="text-xs md:text-base sm:w-1/2">
                    {client?.storeName}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2">
                    Site web :
                  </label>{" "}
                  <div className="text-xs md:text-base sm:w-1/2">
                    {client?.website}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2">
                    Lien de réservation :
                  </label>
                  <div className="text-xs md:text-base sm:w-1/2">
                    {client?.bookingLink}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2">
                    Adresse :
                  </label>
                  <div className="flex flex-col sm:flex-col justify-start sm:w-1/2">
                    <div className="text-xs md:text-base">{client?.adress}</div>

                    <div className="text-xs md:text-base">
                      {client?.zipcode}
                    </div>
                    <div className="text-xs md:text-base">{client?.city}</div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2">
                    Description :{" "}
                  </label>
                  <div className="text-xs md:text-base sm:w-1/2">
                    {client?.description}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2">
                    Numéro SIRET :
                  </label>{" "}
                  <div className="text-xs md:text-base sm:w-1/2">
                    {client?.siret}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between">
                  <label className="text-xs md:text-base font-semibold sm:w-1/2 whitespace-nowrap">
                    Numéro TVA :
                  </label>
                  <div className="text-xs md:text-base sm:w-1/2">
                    {client?.TVA}
                  </div>
                </div>
                <div className="text-center">
                  <a href="/majprofil">
                    <button className="text-xs md:text-base self-center rounded-full bg-salmon p-1 sm:p-2 text-center shadow-xl border-2 border-salmon hover:bg-white hover:text-salmon">
                      Modifier
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
              Mon logo
            </h2>
            <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white flex flex-col justify-center">
              {clientIsSuccess && client?.pictures[0] !== undefined && (
                <img
                  src={`https://api.bikeexplo.com${client.pictures[0].path}`}
                  alt="vignette du parcours"
                  className="rounded-lg h-32 object-contain w-auto my-2"
                />
              )}
              {clientIsSuccess && client?.pictures[0] === undefined && (
                <UploadLogo clientId={Id} />
              )}

              {clientIsSuccess && client?.pictures[0] !== undefined && (
                <button
                  className="text-white rounded-full p-1 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                  type="button"
                  onClick={(e) => {
                    mutate(client.pictures[0].id);
                    setPopup(true);
                    setTimeout(() => setPopup(false), 2000);
                  }}
                >
                  Supprimer une image
                </button>
              )}
              {isSuccess && popup && (
                <div className="text-white my-2 rounded-full bg-green-500 border-2 text-center border-green-500 text-xs ">
                  Logo supprimé
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex-row justify-between mx-72 pb-24">
        <h1 className="display-center text-xs md:text-xl lg:text-2xl font-bold w-auto fs-10 mt-10">
          Vous n'êtes pas connecté, cliquez <a href="/connexion">ici</a> pour
          accéder à Bike Explo Pro.
        </h1>
      </div>
    );
  }
}
