import React, { useState, useEffect } from "react";
import SideBarClient from "../components/sideBar.components";
import Categories from "../components/categories.components";
import axios from "axios";
import jwt_decode from "jwt-decode";
import CodeGeneratePage from "./CodeClient/codeClient";
import SearchBarTrips from "../components/searchBar.components";

export default function ParcoursClient() {
  if (localStorage.getItem("token")) {
    var tok = localStorage.getItem("token");
    var decoded = jwt_decode(tok);
    var Id = decoded.id;
  }

  const [allData, setAllData] = useState([]);

  useEffect(() => {
    axios("https://api.bikeexplo.com/api/trips/publishedclient/" + Id)
      .then((response) => {
        setAllData(response.data);
      })
      .catch((error) => {
        console.log("Une erreur est survenue lors de la recherche" + error);
      });
  }, [Id]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarClient />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <div className="flex flex-row-reverse gap-1 p-2">
          <SearchBarTrips data={allData} />
        </div>
        <div className="flex flex-col w-full">
          <h1 className="text-white font-bold pb-2 text-xs md:text-base">
            Catégories
          </h1>
          <Categories />
        </div>

        <div className="flex flex-col">
          <h2 className="text-white font-semibold my-4 text-xs md:text-base">
            Mes parcours
          </h2>
          <div className="flex flex-row flex-wrap gap-x-3 gap-y-6 w-full">
            {allData.map((value, index) => {
              return (
                <a
                  key={value.id}
                  href={`/parcours/${value.id}`}
                >
                  <div className="bg-salmon text-white w-20 h-28 lg:w-32 lg:h-48 rounded-lg z-0 relative">
                    {value && value?.pictures[0] !== undefined && (
                      <img
                        src={`https://api.bikeexplo.com${value.pictures[0].path}`}
                        alt="vignette"
                        className="absolute z-20 w-20 h-28 lg:w-32 lg:h-48 rounded-lg"
                      />
                    )}
                    <div className="relative z-10 p-1 text-xs lg:text-base">
                      {value.title}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col">
          <h2 className="text-white text-xs sm:text-base font-bold py-3">
            Donner accès à un parcours
          </h2>
          <div className="flex flex-row flex-wrap">
            <CodeGeneratePage />
          </div>
        </div>
      </div>
    </div>
  );
}
