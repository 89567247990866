import React from "react";

export default function sideBarClient() {
  async function logout() {
    localStorage.removeItem("token");
    document.location.pathname = "/connexion";
  }
  return (
    <div className="flex flex-col sm:gap-2 sm:pl-4 pt-2 sm:pt-4 bg-bikeexplogray-light  w-16 sm:w-28 lg:w-2/12">
      <h1 className="text-salmon p-1 lg:p-2 font-bold text-xs sm:text-base lg:text-xl text-center lg:text-left">
        BikeExplo PRO
      </h1>
      <a href="/">
        <button className="flex flex-row gap-1 text-center lg:text-left w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5 focus:text-salmon"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clipRule="evenodd"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Parcours</h3>
        </button>
      </a>
      <a href="/profil">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5 focus:text-salmon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Mon Compte</h3>
        </button>
      </a>
      <a href="/chiffresclés">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5 focus:text-salmon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Chiffres clés</h3>
        </button>
      </a>{" "}
      <a href="/products">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5 focus:text-salmon"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 15V17.3333M6 10.3333V5.66667C6 4.42899 6.52678 3.242 7.46447 2.36683C8.40215 1.49167 9.67392 1 11 1C12.3261 1 13.5979 1.49167 14.5355 2.36683C15.4732 3.242 16 4.42899 16 5.66667L6 10.3333ZM3.5 22H18.5C19.163 22 19.7989 21.7542 20.2678 21.3166C20.7366 20.879 21 20.2855 21 19.6667V12.6667C21 12.0478 20.7366 11.4543 20.2678 11.0168C19.7989 10.5792 19.163 10.3333 18.5 10.3333H3.5C2.83696 10.3333 2.20107 10.5792 1.73223 11.0168C1.26339 11.4543 1 12.0478 1 12.6667V19.6667C1 20.2855 1.26339 20.879 1.73223 21.3166C2.20107 21.7542 2.83696 22 3.5 22Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h3 className="hidden lg:block">Achat de codes</h3>
        </button>
      </a>
      <a href="/support" className="">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5 focus:text-salmon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Aide</h3>
        </button>
      </a>
      <button
        className="flex flex-row gap-1 w-28 lg:w-52 text-salmon p-5 sm:px-7 lg:p-2 mt-16"
        onClick={logout}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 3H11V13H13V3ZM17.83 5.17L16.41 6.59C17.99 7.86 19 9.81 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 9.81 6.01 7.86 7.58 6.58L6.17 5.17C4.23 6.82 3 9.26 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 9.26 19.77 6.82 17.83 5.17Z"
            fill="salmon"
          />
        </svg>

        <h3 className="hidden lg:block">Déconnexion</h3>
      </button>
      <div className="fixed bottom-0 mb-2">
        <a
          href="/mentionslegales"
          className="text-xs text-salmon underline-offset-1 hover:text-white hover:z-10 hidden lg:block"
        >
          Mentions Légales
        </a>
        <svg
          className="w-4 h-4 block lg:hidden text-salmon mx-6 md:mx-7"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
