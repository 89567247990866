// IMPORT COMPONENTS
import React, { useEffect, useState } from "react";
import ReactMapGL, { Marker, Source, Layer } from "react-map-gl";
import axios from "axios";
import { useParams } from "react-router-dom";

// SLIDER
import "tw-elements";
import "../GeoTripsPoi/geotripspoi.css";

// IMPORT PICTURES
import { ImLocation } from "react-icons/im";

// added the following 6 lines.
import mapboxgl from "mapbox-gl";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function GeoTripsPoi() {
  // VARS
  const mapToken =
    "pk.eyJ1IjoicGFyY291cnMtbWFwczEiLCJhIjoiY2x2YjBuM2dtMDNhNzJqcDc2ZWMwbXd0ayJ9.dgYngPnMcgiHigv4sexwXQ";
  const mapStyle = "mapbox://styles/mapbox/streets-v11";
  const [pois, setPois] = useState([]);
  const [lat, setLat] = useState(45.7871036);
  const [lng, setLng] = useState(3.0776881);
  const [zoom, setZoom] = useState(4);
  const { id: tripId } = useParams();
  const [viewport, setViewport] = useState({
    longitude: lng,
    latitude: lat,
    zoom: zoom,
    width: "100%",
    height: "100vh",
  });
  const markers = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: pois.map((poi) => [poi.longitude, poi.latitude]),
    },
  };

  console.log(pois);

  // STATE && FUNCTION
  useEffect(() => {
    const fethPois = async () => {
      const poi = await axios(`https://api.bikeexplo.com/api/trips/${tripId}`);
      setPois(poi.data.pointsOfInterest);
    };
    fethPois();
  }, [tripId]);

  // HTML
  return (
    <ReactMapGL
      mapStyle={mapStyle}
      className="rounded-lg"
      mapboxApiAccessToken={mapToken}
      {...viewport}
      onViewportChange={setViewport}
    >
      {pois?.map((poi) => (
        <Marker
          key={poi.title}
          latitude={poi.latitude}
          longitude={poi.longitude}
        >
          <ImLocation
            style={{ transform: `translate(${-40 / 2}px,${-40}px)` }}
            size="40px"
            color="salmon"
          />
        </Marker>
      ))}
      <Source id="polylineLayer" type="geojson" data={markers}>
        <Layer
          id="lineLayer"
          type="line"
          source="my-data"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "cyan",
            "line-width": 3,
          }}
        />
      </Source>
    </ReactMapGL>
  );
}

export default GeoTripsPoi;
