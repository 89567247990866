import React from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function Faq() {
  async function getFAQ() {
    const { data } = await axios
      .get("https://api.bikeexplo.com/api/faqs")
      .catch(function (error) {
        console.log(error);
        throw new Error(
          `Une erreur est survenue lors de la récupération de la FAQ`
        );
      });
    return data;
  }

  const useFAQ = () => useQuery("FAQ", getFAQ);
  const { data: FAQ, isSuccess, isLoading, isError, error } = useFAQ();

  return (
    <>
      {isError && (
        <span className="text-red">
          {error.message ? error.message : error}
        </span>
      )}
      {FAQ &&
        FAQ.length > 0 &&
        FAQ.map((faq, index) => (
          <div className="bg-bikeexplogray-light p-4 rounded-lg flex flex-col gap-2 w-1/2">
            <h3 className="text-salmon font-semibold">{faq.question}</h3>
            <p className="text-white">{faq.answer}</p>
          </div>
        ))}
    </>
  );
}
